
import { Options, Vue } from 'vue-class-component';
import { BagEstimationClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';
import { baseUrl } from '@/config';
import SummaryCharts from '@/views/calculator/components/summaryCharts.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        SummaryCharts
    }
})
export default class Summary extends Vue {
    
    estimationIdentifier: string = "";
    model: OM.SummaryVM = new OM.SummaryVM;
    loaded: boolean = false;

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }
    
    init() {
        BagEstimationClient.summary(this.estimationIdentifier)
        .then(x => {
            this.model = x;
            this.loaded = true;

            store.state.estimationIdentifier = this.estimationIdentifier;
        })
    }

    downloadPDF() {
        BagEstimationClient.getPDFName(this.estimationIdentifier)
        .then(x => {
            this.$utils.downloadPdf(baseUrl + "api/BagEstimation/DownloadPDF", this.estimationIdentifier, x);
        })
    }

    exportCsv() {
        BagEstimationClient.exportCsv(this.estimationIdentifier)
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.text);
        })
    }

}
